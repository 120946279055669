import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import {
  Divider,
  Button,
  PullQuote,
  StatsPin,
  PinBlockGroup,
  Typography,
  MdxWrapper,
  YouTube,
  PressOfficeWidget,
} from '../../components'

import styles from './PublicationTemplate.module.css'

import ContentRightSidebarLayout from '../../components/Layout/ContentRightSidebar/ContentRightSidebar'
import { format } from 'date-fns'
import Img from '../../components/gatsbyWrappers/Img'

const shortcodes = {
  Divider,
  Button,
  PullQuote,
  StatsPin,
  PinBlockGroup,
  YouTube,
}

export const PublicationTemplate = ({
  title,
  publisher,
  publishedDate,
  featuredImage,
  editor,
  pdf,
  MdxRenderer,
}) => {
  const isPreviewTemplate = typeof MdxRenderer !== 'undefined'
  MdxRenderer = MdxRenderer || MDXRenderer
  const Component = () => (
    <>
      <Typography tag="h1" type="h2">
        {title}
      </Typography>
      <Typography tag="p" type="subHead2" className="mb-4">
        {publisher && (
          <>
            <strong>Author:</strong> {publisher}
          </>
        )}
        <br />
        {publishedDate && (
          <>
            <strong>Published:</strong>{' '}
            {format(new Date(publishedDate), 'MMM yyyy')}
          </>
        )}
      </Typography>
      <div className={styles.contentGrid}>
        <Img src={featuredImage} alt={title} />
        <MdxWrapper>
          <MdxRenderer>{editor}</MdxRenderer>
          {pdf && (
            <p>
              <a
                className={`button ${styles.downloadButton}`}
                href={pdf}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download PDF
              </a>
            </p>
          )}
        </MdxWrapper>
      </div>
    </>
  )
  return isPreviewTemplate ? (
    <Component />
  ) : (
    <MDXProvider components={shortcodes}>
      <Component />
    </MDXProvider>
  )
}

PublicationTemplate.propTypes = {
  title: PropTypes.string,
  publisher: PropTypes.string,
  publishedDate: PropTypes.string,
  featuredImage: PropTypes.string,
  editor: PropTypes.string,
  pdf: PropTypes.string,
  MdxRenderer: PropTypes.func,
}

const Article = ({ data }) => {
  const {
    frontmatter: {
      header,
      title,
      publisher,
      editor,
      publishedDate,
      featuredImage,
      pdf,
    },
  } = data.mdx

  return (
    <ContentRightSidebarLayout header={header}>
      <div>
        <PublicationTemplate
          title={title}
          publisher={publisher}
          publishedDate={publishedDate}
          featuredImage={featuredImage}
          editor={editor}
          pdf={pdf}
        />
      </div>
      <div>
        <PressOfficeWidget />
      </div>
    </ContentRightSidebarLayout>
  )
}

Article.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default Article

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      id
      fields {
        slug
      }
      frontmatter {
        ...headerFields
        title
        publisher
        publishedDate
        featuredImage {
          childCloudinaryMediaImage {
            fixed(width: 300) {
              height
              src
              srcSet
              width
            }
          }
        }
        editor
        pdf
      }
    }
  }
`
